import { GetStaticProps } from "next";
import { revalidationInterval } from "@/utils/revalidationInterval";
import Block from "@/components/block";
import { LinkTo } from "@/components/linkTo";
import {
  HeaderNavigationProps,
  HeaderNavigationQuery,
} from "@/components/HeaderNavigation";
import HeaderNavigation from "@/components/HeaderNavigation/HeaderNavigation";
import { getClient } from "@/lib/sanity.server";

export function NotFound() {
  const primaryLineOne = "Oops, something";
  const primaryLineTwo = "went wrong.";
  const errorCode = "404";
  const primaryText = (
    <>
      <span>{primaryLineOne}</span>
      <br />
      <span>{primaryLineTwo}</span>
    </>
  );
  const secondaryText =
    "We're sorry, but it looks like the page that you're looking for can't be found.";

  return (
    <Block className="mt-20 text-center">
      <div className="flex flex-col items-center">
        <h1 className="type-head-2 sm:type-head-1 md:type-broadcast-1 mt-10 bg-gradient-to-b from-teal to-green bg-clip-text pb-[1rem] font-rebrand text-[70px] font-bold leading-[75px] text-colorless md:text-[130px] md:leading-[130px]">
          <em>{primaryText}</em>
        </h1>
        <p className="type-body-2 mt-8">
          <strong>Error {errorCode}</strong>
        </p>
        <p className="type-body-2">{secondaryText}</p>

        {errorCode === "404" && (
          <div className="mx-auto mt-8 flex flex-col items-center justify-center md:mt-16 md:flex-row">
            <LinkTo.Home
              className="px-10 text-black md:mr-2"
              style="button"
              buttonColor="green"
              buttonStyle="secondary"
            >
              Back to Home
            </LinkTo.Home>

            <LinkTo.External
              url="mailto:hello@pairtreefamily.com"
              className="ml-2 mt-3 px-10 text-black md:mt-0"
              style="button"
              buttonColor="green"
              buttonStyle="secondary"
            >
              Contact Us
            </LinkTo.External>
          </div>
        )}
      </div>
    </Block>
  );
}

export type ErrorPageContentProps = HeaderNavigationProps;

export type ErrorPageProps = ErrorPageContentProps;

export function ErrorPage(props: ErrorPageProps) {
  return (
    <>
      <HeaderNavigation navSections={props.navSections} />
      <NotFound />
    </>
  );
}

export const getStaticProps: GetStaticProps<ErrorPageProps> = async (
  context,
) => {
  const preview: boolean = !!context.draftMode;

  const navPayload: HeaderNavigationProps = await getClient(preview).fetch(
    HeaderNavigationQuery.query,
    {},
  );

  return {
    props: navPayload,
    revalidate: revalidationInterval,
  };
};

export default ErrorPage;
